import { Config, ConfigEnvironment } from './ConfigType';

export const config: Config = {
  env: ConfigEnvironment.STAGING,
  release: '5fd8c963359b0c089c8077a3b7af82e6e3d069a7',
  sentryDSN: undefined,
  websiteURL: 'http://rc.teslaeesti.com/',
  apiURLFrontend: '/api_api/',
  plausible: {
    siteId: 'rc.teslaeesti.com',
    url: 'https://plausible.stampapp.io/'
  }
};
